//é onde colocamos resets, box-sizing, etc.

* {
    box-sizing: border-box;
    font-family: $font;
    font-size: 16px;
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100%;
    min-height: 100%;
}

body {
    background-color: #FFF;
    min-width: 320px;
}

.bgGray {
    background-color : #f1f1f1;;
}