.is-center {
    text-align: center !important;
}

.is-hidden { 
    display: none !important;
}

.mb-24 {
    margin-bottom: 24px!important;
}

.deco-none {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.only-xs {
    display: block;
    @media (min-width: 768px) {
        display: none;
    }
}