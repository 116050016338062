.footer {
    color: #fff;
    text-align: center;
    background-color: #212121;

    .name {
        margin: 0;
        padding: 20px;
    }

    .developed {
        color : #ccc;
        margin: 0;
        opacity: .5;

        a {
            color : #ccc;
            text-decoration: underline;

        }
    }
}
