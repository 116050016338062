@import "settings/settings";

@import "tools/tools";

@import "generic/generic";

@import "base/base";

@import "objects/btn";
@import "objects/service-card";
@import "objects/info";
@import "objects/img-thumbnail";

@import "components/navbar";
@import "components/footer";
@import "components/breadcrumb";
@import "components/content";
@import "components/tablatura";
@import "components/cifra-options";
@import "components/music-content";


@import "trumps/trumps";