.content {
    display: flex;
    flex: 1;
    margin: 0 16px;
}

.columns {
    flex: 1;
    display: flex;
    flex-flow: row wrap;
    @media screen and (min-width: 768px) {
        display: flex;
        flex-flow: row wrap;
    }
}

.main-music {
    background: #fdfdfd;
    flex: 1;
    max-width: 100vw;
    margin: 0 0 48px;
    padding: 0 16px 0 0;
    order: 2;
    @media screen and (min-width: 768px) {
        display: initial;
        margin: 8px 0 8px 64px;
    }
    @media screen and (min-width: 1024px) {
        display: initial;
        margin: 16px;
    }
}

.sidebar-second {
    display: none;
    padding: 16px;
    order: 3;
    width: 100%;
    @media screen and (min-width: 1024px) {
        display: initial;
        margin: 16px 0;
        width: 15%;
    }
}

.aside-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    @media screen and (min-width: 768px) {
        flex-flow: column wrap;
        position: fixed;
    }
}