/* Style the list */

ul.breadcrumb {
    background-color: #f5f5f5;
    border-radius: 4px;
    list-style: none;
    margin: 16px;
    padding: 10px 16px;
}

ul.breadcrumb li {
    color: #777;
    display: inline;
    font-size: 12px;
}

ul.breadcrumb li+li:before {
    color: #c9c9c9;
    content: "/\00a0";
    padding: 8px;
}

ul.breadcrumb li a {
    color: #337ab7;
    text-decoration: none;
}

ul.breadcrumb li a:hover {
    color: #028bc9;
    text-decoration: underline;
}