.sidebar-first {
    margin: 8px 0;
    padding: 16px;
    order: 1;
    width: 100%;
    @media screen and (min-width: 768px) {
        width: 20%;
    }
    @media screen and (min-width: 1024px) {
        margin: 16px 0;
    }
    figure {
        display: block;
        margin: auto;
        width: 150px;
        @media screen and (min-width: 768px) {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            margin: 16px auto;
        }
    }
    .cifra-options {
        display: flex;
        flex-flow: row wrap;
        font-size: 10px;
        justify-content: center;
        @media screen and (min-width: 768px) {
            justify-content: left;
        }
        @media screen and (min-width: 768px) {
            justify-content: center;
        }
        .list-option {
            opacity: 0;
            transition: all .5s ease-in;
            bottom: 70px;
            position: fixed;
            right: 10px;
            z-index: 4;
            &.open {
                opacity: 1;
                transition: opacity .5s ease-in;
            }
            @media (min-width: 768px) {
                bottom: auto;
                position: relative;
                right: auto;
                opacity: 1;
                z-index: 1;
            }
        }
        .option {
            background-color: #424242;
            border-radius: 5px;
            color: #fff;
            display: flex;
            justify-content: space-between;
            margin: 8px 0;
            text-align: center;
            width: 100px;
            &:hover {
                border-color: orangered;
            }
            @media (min-width: 768px) {
                background-color: rgb(189, 189, 189);
                color: #424242;
            }
        }
        .type-option {
            display: block;
            font-size: 10px;
            margin: auto;
            padding: 8px;
        }
        .option-before {
            border-right: 1px solid #E0E0E0;
            padding-right: 5px;
            padding: 8px;
        }
        .option-after {
            border-left: 1px solid #E0E0E0;
            padding-left: 5px;
            padding: 8px;
        }
        .option-after:hover,
        .option-before:hover {
            color: orangered;
            cursor: pointer;
            font-size: 16px;
        }
    }
}
