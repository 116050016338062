.music-content {
    max-width: 90vw;
    .music-cifra {
        display: block;
        padding: 9.5px;
        font-size: 13px;
        line-height: 1.42857143;
        color: #333;
        word-break: break-all;
        word-wrap: break-word;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
        border-radius: 4px;
        overflow: scroll;
        @media screen and (min-width: 1024px) {
            overflow: hidden;
        }
    }
}