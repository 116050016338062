.btn {
    cursor: pointer;
    border-radius: 4px;
    font-weight: bold;
    padding: 10px;
    transition: all .5s ease-in;
    text-transform: uppercase;
    width: 100%;
}

.fab-button {
    background-color: #0D47A1;
    border-radius: 50%;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .12);
    bottom: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    height: 50px;
    position: fixed;
    right: 30px;
    transition: all 0.3s ease;
    width: 50px;
    z-index: 3;
    &.rotate {
        transform: rotate(45deg);
    }
    .icon {
        height: 20px;
        fill: #fff;
        width: 20px;
    }
}
