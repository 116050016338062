.img-thumbnail {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: block;
    height: auto;
    line-height: 1.42857143;
    margin: auto;
    margin-bottom: 18px;
    max-width: 100%;
    padding: 4px;
    transition: all .2s ease-in-out;
}