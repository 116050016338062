$bg: #f3f3f3;
$fc: #333;
$topnavh: 60px;
$botnavh: 40px;
$navcolour: #fff;
$navbgcolour: #1976D2;
$mobnavw: 200px;

.clearfix:before,
.clearfix:after {
    clear: both;
    content: '';
    display: table;
}

.container {
    position: relative;
    width: calc(100% - 32px);
    margin: 0 auto;
    transition: max-width 0.3s;
    display: flex;
    flex-flow: row wrap;
    &:before,
    &:after {
        clear: both;
        content: '';
        display: table;
    }
    @media screen and (max-width: 1050px) {
        max-width: 768px;
    }

    .feature-button {
        align-self: center;
        background-color: #fff;
        border: 2px solid #fff;
        border-radius: 0.2em;
        color: #000;
        cursor: pointer;
        font-size: 14px;
        height: 35px;
        margin: 0 auto;
        max-width: 480px;
        padding: 10px;
        width: 100%;
        &:focus {
            box-shadow: 0 0 1px 1px lightblue;
        }
    }
}


.waves-effect.waves-white .waves-ripple {
    background-color: rgba(255, 255, 255, 0.3);
}

.invisible-checkbox {
    opacity: 0;
    display: none;
    position: fixed;
    top: 0;
    // left: 200vw;
}

nav {
    position: relative;
    width: 100%;
    margin: 0;
    height: $topnavh + $botnavh;
    font-weight: 300;
    .fixed-nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: $topnavh + $botnavh;
        background: $navbgcolour;
        color: $navcolour;
        overflow: hidden;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
        z-index: 3;
        .top {
            position: relative;
            float: left;
            width: 100%;
            height: $topnavh;
            line-height: $topnavh;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
            .logo {
                margin: 0 0 0 16px;
                font-size: 20px;
            }
        }
        .bottom {
            position: relative;
            float: left;
            width: 100%;
            height: $botnavh;
            line-height: $botnavh;
            background: rgba(0, 0, 0, 0.2);
            overflow: hidden;
            ul {
                position: relative;
                float: left;
                list-style: none;
                margin: 0;
                padding: 0;
                li:not(.pointer) {
                    position: relative;
                    float: left;
                    a {
                        display: block;
                        padding: 0 16px;
                        height: $botnavh;
                        line-height: $botnavh;
                        color: $navcolour;
                        text-decoration: none;
                        transition: background 0.3s;
                        &:hover,
                        &:focus {
                            outline: none;
                            background: rgba(0, 0, 0, 0.25);
                        }
                    }
                    &.active a {
                        background: rgba(0, 0, 0, 0.15);
                        &:hover,
                        &:focus {
                            background: rgba(0, 0, 0, 0.25);
                        }
                    }
                }
                .pointer {
                    display: none;
                    height: 10px;
                    width: 10px;
                    position: absolute;
                    top: -5px;
                    left: -5px;
                    background: $navbgcolour;
                    transition: transform 0.3s;
                    z-index: 2;
                    transform: translate3d(0, 0, 0) rotate(45deg);
                    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    nav .fixed-nav {
        height: $topnavh;
        overflow: visible;
        box-shadow: none;
        .burger {
            position: fixed;
            top: 0;
            right: 0;
            height: $topnavh;
            width: $topnavh;
            z-index: 9001;
            cursor: pointer;
        }
        $pixel: 4px;
        $color: #fff;
        .burger {
            transition: background 0.3s;
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0px);
                height: $pixel;
                width: $pixel;
                background-color: $color;
                box-shadow: ($pixel * -2) ($pixel * -2) 0 0 $color, // Top left
                ($pixel * 0) ($pixel * -2) 0 0 $color, // Top middle
                ($pixel * 2) ($pixel * -2) 0 0 $color, // Top right
                ($pixel * -2) ($pixel * 0) 0 0 $color, // Middle left
                ($pixel * 2) ($pixel * 0) 0 0 $color, // Middle right
                ($pixel * -2) ($pixel * 2) 0 0 $color, // Bottom left
                ($pixel * 0) ($pixel * 2) 0 0 $color, // Bottom middle
                ($pixel * 2) ($pixel * 2) 0 0 $color; // Bottom right
                transition: box-shadow 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
            }
        }
        .top {
            z-index: 3;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            .container {
                height: $topnavh;
            }
            .logo {
                margin-left: 0;
            }
            .feature-button {
                background-color: #fff;
                border: 2px solid #fff;
                border-radius: 0.2em;
                cursor: pointer;
                position: absolute;
                right: auto;
                top: calc(100% + 16px);
                left: calc(#{$mobnavw / 2} - 14px);
                border-color: #333;
                color: #333;
                font-weight: 400;
                height: 50px;
                line-height: 46px;
                padding: 0;
                font-size: 18px;
                text-align: center;
                width: $mobnavw - 32px;
                transform: translate3d(#{-$mobnavw * 2}, 0, 0);
                transition: transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
            }
        }
        .bottom {
            position: fixed;
            top: $topnavh;
            width: $mobnavw;
            height: calc(100vh - #{$topnavh});
            overflow-y: auto;
            background: #FFF;
            box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
            z-index: 2;
            transform: translate3d(-120%, 0, 0);
            transition: transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
            will-change: transform;
            .container {
                margin: 0;
                width: 100%;
                max-width: 100%;
            }
            ul {
                top: $topnavh + 24px;
                padding-top: 10px;
                float: left;
                width: 100%;
                border-top: 1px solid rgba(0, 0, 0, 0.2);
                li:not(.pointer) {
                    width: 100%;
                    a {
                        color: #333;
                        font-weight: 400;
                    }
                }
                .pointer {
                    background: rgba(0, 0, 0, 0.6);
                }
            }
        }
        #mobile-nav-check:focus+.burger {
            background-color: rgba(0, 0, 0, 0.2);
        }
        #mobile-nav-check:checked+.burger:after {
            box-shadow: ($pixel * -2) ($pixel * -2) 0 0 $color, // Top left
            ($pixel * 1) ($pixel * -1) 0 0 $color, // Top middle
            ($pixel * 2) ($pixel * -2) 0 0 $color, // Top right
            ($pixel * -1) ($pixel * -1) 0 0 $color, // Middle left
            ($pixel * 1) ($pixel * 1) 0 0 $color, // Middle right
            ($pixel * -2) ($pixel * 2) 0 0 $color, // Bottom left
            ($pixel * -1) ($pixel * 1) 0 0 $color, // Bottom middle
            ($pixel * 2) ($pixel * 2) 0 0 $color; // Bottom right
        }
        #mobile-nav-check:checked~.top .feature-button {
            transform: translate3d(-50%, 0, 0);
        }
        #mobile-nav-check:checked~.bottom {
            transform: translate3d(0, 0, 0);
        }
    }
}

